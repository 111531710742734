footer {
  text-align: center;
  font-size: 0.75rem;
  padding: 2rem;
  background: var(--bg-2);
}

@media (min-width: 48rem) {
  footer {
    padding: 4rem 8rem 2rem;
  }
}
