.script-section {
  padding: 4rem;
  background: var(--bg-2);
}

.script-heading {
  margin-top: 2rem;
}

.script-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.script-item {
  border-color: #000;
  border-style: solid;
  border-width: 0 0 1px 0;
  padding: 2rem;
}

.script-item:nth-child(odd) {
  border-right-width: 1px;
  padding-right: 1rem;
}

.script-item:last-child {
  border-bottom: 0;
}

.script-item-heading {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  margin-bottom: 2rem;
}

.grid-content-heading {
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 200;
  margin-bottom: 0;
}

.grid-content {
  font-size: 1.75rem;
  margin-bottom: 2.5rem;
}

ol {
  list-style-type: none;
  padding-left: 1.5rem;
}

li {
  margin-bottom: 0.5rem;
}

details div {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

details[open] div {
  opacity: 1;
}

details[open] summary ~ * {
  animation: sweep 0.5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-left: -10px;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

.story-section {
  background: var(--bg-2);
  padding: 2rem;
}

.story-section p,
li {
  font-size: 1.75rem;
  font-style: italic;
}
