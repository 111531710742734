html,
body {
  min-height: 100dvh;
  scroll-behavior: smooth;
}

:root {
  --bg-1: #dbe9ee; /* light blue */
  --bg-2: #fff;
  --bg-3: #457b9d; /* primary blue */
  --bg-4: #47cf78; /* secondary green */

  --fg-1: #023c40; /* dark green */
  --fg-2: #000;
  --fg-3: #228cca; /* blue */

  --font-1: "Cabin", sans-serif;
  --font-2: "Alata", sans-serif;
}

main {
  color: var(--fg-2);
}

.container {
  margin: auto;
  max-width: 80rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: var(--font-1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.marketing-heading {
  font-size: 2.75rem;
  font-weight: 900;
  letter-spacing: -0.025em;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.script-content {
  font-weight: 400;
  font-family: var(--font-1);
  margin-top: 0;
}

.text-center {
  text-align: center;
}

.btn {
  appearance: none;
  background: none;
  border: 1px solid #000;
  padding: 0.75rem 1.25rem;
  cursor: pointer;
  transition: all 0.5s;
  text-decoration: none;
}

.btn:hover:not(disabled) {
  background: var(--fg-1);
}

.btn:disabled {
  opacity: 0.6;
}

.action-btn {
  appearance: none;
  background: var(--bg-3);
  border-radius: 0.5rem;
  border-style: none;
  color: #fff;
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 0.875rem 2.25rem;
  transition: all 0.2s;
}

.action-btn.secondary-action-btn {
  background: var(--bg-4);
}
