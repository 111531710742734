p,
li {
  font-size: 1.5rem;
}

.brandscript-main {
  background: var(--bg-2);
}

.get-clear-link {
  position: relative;
  right: 4rem;
  top: 2rem;
}

.brandscript-btn {
  display: block;
  margin: 8rem auto;
}

.website-ideas-section {
  padding: 2rem;
}
