#main {
  height: 100dvh;
}

.script-heading {
  font-size: 3.5rem;
  font-weight: 700;
  font-family: var(--font-2);
  text-align: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.upsell {
  margin-top: 8rem;
}

.upsell .unlock-message {
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 100;
}

.buy-btn {
  display: block;
  margin-bottom: 6rem;
  margin-left: auto;
  margin-right: auto;
}

.fade-enter {
  opacity: 0;
  transform: translateY(-10%);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: all 1000ms;
}
