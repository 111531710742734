.who-made-this {
  padding: 2rem;
}

.who-made-this a {
}

.who-made-this p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.who-made-this-block {
}

.img {
  object-fit: cover;
}

.who-made-this .img {
  display: block;
  margin: auto;
  width: 100%;
}

.img.jon {
  border-radius: 100%;
}

.who-made-this-block-heading {
  font-family: var(--font-2);
  font-size: 2.25rem;
  font-weight: 500;
}

.content {
  margin-left: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.buttons-container {
  width: 100%;
  margin-top: 1rem;
}

.action-btn.book-btn {
  display: block;
  font-size: 1.25rem;
  text-decoration: none;
  text-align: center;
  margin-bottom: 1rem;
}

@media (min-width: 48rem) {
  .who-made-this-block {
    display: flex;
    gap: 4rem;
  }

  .img-right {
    flex-direction: row-reverse;
  }

  .who-made-this .img.jon {
    width: 40%;
    max-width: 22rem;
    margin-left: 4rem;
  }

  .who-made-this-block-heading {
    font-size: 2.75rem;
  }
}

@media (min-width: 64rem) {
  .buttons-container {
    display: flex;
    gap: 2rem;
  }

  .who-made-this .img.jon {
    margin-left: 8rem;
  }
}
