.form {
  background: var(--bg-2);
  color: var(--fg-2);
  display: grid;
  height: 100%;
  padding: 6rem 2rem 24rem;
  place-items: center;
}

@media (min-width: 48rem) {
  .form {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

.heading {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 800;
  padding-bottom: 3rem;
  margin-bottom: 0;
}

.question-description {
  font-size: 1.75rem;
  font-style: italic;
  margin-bottom: 8rem;
}

.radio-group {
  display: flex;
  flex-direction: column;
  font-size: 1.75rem;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.radio-group > * {
  margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.other-radio-input {
  display: flex;
  align-items: center;
}

.other-radio-input .input {
  width: 60%;
  font-size: 1.75rem;
  margin-bottom: 0;
}

.input {
  appearance: none;
  background: none;
  border-color: var(--fg-2);
  border-width: 0 0 2px 0;
  color: var(--fg-2);
  display: block;
  font-size: 2.25rem;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  width: 90%;
}

.btn-container {
  display: flex;
  justify-content: center;
}

.btn-container > .btn {
  margin-right: 1rem;
}

.form-fade-enter {
  opacity: 0;
  transform: translateX(-10%);
}

.form-fade-exit {
  opacity: 1;
  transform: translateX(0%);
}

.form-fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.form-fade-exit-active {
  opacity: 0;
  transform: translateX(10%);
}

.form-fade-enter-active,
.form-fade-exit-active {
  transition: all 300ms;
}

.progress-bar-container {
  width: 50%;
  background-color: var(--bg-2);
  border: 1px solid var(--fg-1);
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.progress-bar {
  height: 0.5rem;
  background-color: var(--bg-3);
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
}

@media (min-width: 64rem) {
  .heading {
    font-size: 3.25rem;
  }

  .form-questions {
    align-items: center;
    display: flex;
  }

  .form-questions > * {
    flex-basis: 50%;
  }

  .form-questions-header {
    border-right: 2px var(--fg-2) solid;
    padding-right: 4rem;
  }

  .input-form {
    padding-left: 4rem;
  }
}
