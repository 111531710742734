.header-bg {
  align-items: center;
  background: var(--bg-1);
  display: flex;
  height: 100dvh;
  justify-content: center;
  width: 100%;
}

.header-container {
  align-items: center;
  gap: 4rem;
  justify-content: space-between;
  padding: 4rem;
}

.header-title-container {
}

.header-title {
  color: var(--fg-1);
  font-family: var(--font-2);
  font-size: 1.875rem;
  font-weight: 600;
  margin-top: 3rem;
}

.header-title-accent {
  color: var(--fg-3);
  font-family: var(--font-2);
  font-size: 3rem;
  font-weight: 800;
}

.header-body {
  font-weight: 400;
  font-size: 1.325rem;
  color: var(--fg-1);
}

.logo-link {
  position: absolute;
  right: 4rem;
  top: 2rem;
}

.logo {
  width: 10rem;
  margin-left: auto;
  display: block;
}

.get-started {
  margin-top: 1.25rem;
}

#three-d-canvas-container {
  display: none;
}

@media (min-width: 48rem) {
  .header-title {
    font-size: 2rem;
  }

  .header-title-accent {
    font-size: 3.25rem;
  }

  .header-body {
    font-size: 1.5rem;
  }
}

@media (min-width: 64rem) {
  .header-container {
    display: flex;
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .header-title {
    font-size: 3.5rem;
    margin-bottom: 3rem;
  }

  .header-title-accent {
    font-size: 4.25rem;
  }

  .header-body {
    font-size: 1.75rem;
  }
}

@media (min-width: 80rem) {
  #three-d-canvas-container {
    display: block;
    height: 50rem;
    margin: auto;
    max-width: 80vw;
    width: 50rem;
  }
}
