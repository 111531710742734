.form.contact-form {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding-bottom: 4rem;
  max-width: 85rem;
}

.form.contact-form > * {
  flex-basis: 50%;
}

.contact-form .heading {
  border-right: 2px var(--fg-2) solid;
  margin-top: 0;
  padding-right: 4rem;
}

.contact-form-inputs {
  padding-left: 4rem;
}

.contact-form-label {
  font-size: 1.5rem;
  max-width: 50rem;
  width: 100%;
}

.contact-form .input {
  margin-bottom: 2rem;
  margin-left: 0;
  margin-right: 0;
}

.contact-form .btn {
  margin-top: 2rem;
}
