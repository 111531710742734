.wireframe {
  background: #fff;
  color: var(--fg-1);
  text-align: center;
  width: 100%;
  border: 1px solid var(--fg-1);
  box-sizing: border-box;
}

.wireframe > * {
  padding: 2rem;
}

.preview {
  filter: blur(16px);
}

.wireframe-heading {
  background: var(--bg-1);
}

.wireframe-heading-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.wireframe-heading-h1 {
  font-size: 1.5rem;
  font-weight: 600; /* TODO: Check to see that this is actually working, I don't think we're importing 600 */
  margin-bottom: 1rem;
}

.wireframe-logo {
  border: 2px solid var(--fg-1);
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
}

.wireframe-cta {
  padding: 0.5rem 1rem;
  background: var(--bg-3);
  font-size: 1.125rem;
  border: 1px solid var(--fg-1);
  width: fit-content;
}

.content-bold {
  font-weight: 600;
  margin-bottom: 1rem;
}

.content-normal {
  margin-bottom: 1rem;
  font-size: 1rem;
}

.content-heading {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
}

.image-item {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 0.5rem;
}

.image-spot {
  background: #d3d3d3;
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.images-and-texts {
  margin-bottom: 3rem;
}

.image-and-text {
  margin-bottom: 2rem;
  text-align: left;
  gap: 1rem;
}

.image-block {
  background: #d3d3d3;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  text-align: center;
  flex-shrink: 0;
}

.wireframe-footer {
  background: var(--bg-1);
}

.wireframe-junk-drawer {
  background: var(--fg-1);
  color: #fff;
}

@media (min-width: 48rem) {
  .image-and-text {
    display: flex;
  }

  .image-block {
    min-width: 14rem;
    width: 30%;
  }

  .images-list {
    display: flex;
    justify-content: space-around;
  }

  .image-item {
    max-width: 25%;
  }
}
